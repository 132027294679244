import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaInputDefaultVariants = {
  "size": "small"
}
const aromaInputCompoundVariants = []

const aromaInputSlotNames = [
  [
    "input",
    "aroma-input__input"
  ],
  [
    "container",
    "aroma-input__container"
  ]
]
const aromaInputSlotFns = /* @__PURE__ */ aromaInputSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaInputDefaultVariants, getSlotCompoundVariant(aromaInputCompoundVariants, slotName))])

const aromaInputFn = memo((props = {}) => {
  return Object.fromEntries(aromaInputSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaInputVariantKeys = [
  "size",
  "status"
]
const getVariantProps = (variants) => ({ ...aromaInputDefaultVariants, ...compact(variants) })

export const aromaInput = /* @__PURE__ */ Object.assign(aromaInputFn, {
  __recipe__: false,
  __name__: 'aromaInput',
  raw: (props) => props,
  variantKeys: aromaInputVariantKeys,
  variantMap: {
  "size": [
    "small",
    "medium"
  ],
  "status": [
    "error",
    "warning"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaInputVariantKeys)
  },
  getVariantProps
})